<template>
  <modal-card :title="'Promotion'">
    <promotion-details
      ref="promotion"
      @processing="processing = $event"
      @valid="isValid = $event"
      @loading="loading = $event"
      @hasChange="hasChange = $event"
      @success="onSuccess"
    >
      <div slot="footer-details" />
    </promotion-details>

    <button
      slot="footer"
      :class="{ 'is-loading': processing }"
      :disabled="!isValid || loading || processing || !hasChange"
      type="submit"
      class="button is-success"
      @click="save"
    >
      Save
    </button>
  </modal-card>
</template>

<script>
export default {
  name: "PromotionModal",
  components: {
    "promotion-details": () => import("@shared/promotions/_promotionDetails")
  },
  props: {
    promotionId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      isValid: false,
      hasChange: true
    };
  },
  methods: {
    save() {
      this.$refs.promotion.save();
    },
    onSuccess(promotionId) {
      this.$router.push(`/admin/settings/promotions/${promotionId}`);
      this.$emit("success");
    }
  }
};
</script>
